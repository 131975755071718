<script setup lang="ts">
import  { type PropType } from 'vue'

const { 
  collection, 
  fetchCollectionByHandle,
} = useCollection()

const props = defineProps({
  collectionHandle: {
    type: String as PropType<string>,
    required: true
  }
})

fetchCollectionByHandle(props.collectionHandle)
</script>

<template>
  <NuxtLink
    :to="{
      name: 'collections-collectionHandle',
      params: {
        collectionHandle: collectionHandle,
      }
    }"
    class="group w-full bg-blue-600 bg-cover bg-top rounded-tl-none rounded-b-3xl rounded-tr-3xl block relative overflow-hidden"
  >
    <div class="grid grid-cols-1 h-full rounded-tl-none rounded-b-3xl rounded-tr-3xl overflow-hidden">
      <div
        class="w-full h-72 lg:h-96 xl:h-72 relative overflow-hidden"
      >
        <div class="absolute z-10 inset-0 mx-auto h-72 lg:h-96 xl:h-72 overflow-hidden bg-opacity-40"></div>
        <NuxtPicture
          v-if="collection && collection?.image"
          provider="shopify"
          :src="collection.image.url" 
          :alt="collection.image.altText ?? `${collection.title} | Kind & Conscious`" 
          :width="80"
          :height="200" 
          :imgAttrs="{
            class: 'object-center object-cover w-full h-72 lg:h-96 xl:h-72 transform group-hover:scale-110 transition-all duration-500 overflow-hidden',
            decoding: 'async',
            fetchpriority: 'high',
          }"
        />
      </div>
      <div class="w-full h-20 bg-gradient-to-br from-blue-600 to-blue-500">
        <div
          class="w-full h-full flex items-center justify-center rounded-b-2xl transition-all duration-300 ease-in-out"
          :class="{
            'opacity-0': !collection,
            'opacity-100': collection,
          }"
        >
          <h2  
            class="text-white text-center p-5 px-5 text-sm sm:text-base uppercase font-bold tracking-widest"
          >
            {{ collection?.title }}
          </h2>
        </div>
      </div>
    </div>
  </NuxtLink>
</template>